import React from "react"

import Layout from "../layouts/layout"
import { FaCheckCircle } from "react-icons/fa"

import Breadcrumb from "../components/breadcrumb"

function AboutPage() {
    return(
        <Layout title="About">
            <div className="mt-3">
                <Breadcrumb title="About" />
            </div>
            <h1 className="title">About : sn42</h1>
            <p>Master's degree. Interested in computational neuroscience using fMRI.</p>
            
            <h4>Features</h4>
            <div>
                <span className="text-success d-inline-block" title="blazing">
                    <FaCheckCircle size={26} style={{ color: "success" }} />
                </span>
                <p className="d-inline-block ms-3 w-75 align-top" align="left"><strong>Interests : </strong>Neuroimaging (fMRI), Computational Neuroscience, Language, Statistical Machine Learning.</p>
            </div>
            <div>
                <span className="text-success d-inline-block" title="tags">
                    <FaCheckCircle size={26} style={{ color: "success" }} />
                </span>
                <p className="d-inline-block ms-3 w-75 align-top" align="left"><strong>Programming Language : </strong>Python, Typescript, Matlab, etc.</p>
            </div>
            <br />
            <div>
                <p>
                日々の備忘録や基礎的なコラムを掲載していこうと考えています。
                </p>
            </div>
        </Layout>
    )
}

export default AboutPage
